import React from "react"
import { Link } from "gatsby"
import { Row, Col, Container, Breadcrumb, Image } from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import LuteinComplexMobile from "./../../images/stavien-sinovialna-stava-header.jpg"
import LuteinComplexTablet from "./../../images/stavien-sinovialna-stava-header.jpg"
import LuteinComplexDesktop from "./../../images/stavien-sinovialna-stava-header.jpg"
import LuteinComplexXL from "./../../images/stavien-sinovialna-stava-header.jpg"

import Stava from "./../../images/stavien-sinovialna-stava.jpg"

const StavienStavi = () => (
  <Layout backButton={true} pageInfo={{ pageName: "stavien-stavi" }}>
    <Seo title="Какво е устройството на свободно-подвижните стави (синовиални стави)?" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/articles">СТАТИИ</Link>
            </li>
            <Breadcrumb.Item active>
              КАКВО Е УСТРОЙСТВОТО НА СВОБОДНО-ПОДВИЖНИТЕ СТАВИ
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Какво е устройството на свободно-подвижните стави (синовиални стави)?"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={12} className="">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Какво е устройството на свободно-подвижните стави (синовиални
            стави)?
          </h1>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={12}>
          <p>
            Мястото, където две повърхности на костите са в съприкосновение и
            осъществяват движение една спрямо друга, в комбинация със сухожилия,
            свързващи определени мускули с костта, се нарича става. Повърхността
            на костите, свързващи се в дадена става са покрити с тънък слой
            тъкан, наречена ставен хрущял.
          </p>
        </Col>
        <Col sm={5}>
          <Image className="w-100" src={Stava} />
        </Col>
        <Col sm={7}>
          <p>
            Целите костни части на ставата са обхванати в капсула. Между
            ставните хрущяли се намира ставната цепнатина. Ставата е изпълнена с
            малко количество течност (смазващата ставите течност), наречена
            синовиална (или“синовия” от латински означава “като яйце”), която
            осигурява гладкото приплъзване на ставните хрущяли един спрямо друг.
            Освен това синовията изхранва хрущялите, филтрира вредните вещества
            и блокира достъпа им.Тя намалява значително триенето между
            хрущялните ставни повърхности, като ги държи на известно разстояние
            една от друга и осигурява гладкото приплъзване на костите една
            спрямо друга. Така се възпрепятства износването на хрущяла. Повечето
            стави на тялото са синовиални.
          </p>
        </Col>
        <Col>
          <p>
            Синовиалните стави са “големите” стави: раменните, лакътните,
            тазобедрените, коленните, глезенните. Предназначението на хрущяла и
            синовията е да поемат заедно натоварванията и ударите, които ставите
            постоянно изпитват (като амортисьор). Хранителните материали
            хрущялът си набавя главно от синовиалната течност. Тези вещества
            постоянно го обновяват и предпазват от износване.
          </p>
          <p>
            С възрастта хрущялът се износва, синовиална течност се изменя, губи
            еластичните си свойства, способността ефективно да смазва ставите и
            да поема натоварванията им. Ставите стават уязвими. Крайният
            резултат е ограничена подвижност и стабилност на ставата. Уврежда се
            цялата структура, липсва корелация, тъй като с ограничаване на
            ставната подвижност протективните свойства на ставната течност
            прогресивно се изчерпват и подхрущялната кост се уврежда.
          </p>
        </Col>
      </Row>

      <Row className="product-container">
        <Col>
          <p>Можете да откриете СТАВИЕН в най-близката до вас аптека.</p>
          <p>
            Телефон за консултация и поръчка:{" "}
            <a href="tel:029446006">02 944 60 06</a>
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <h3>
            <Link to="/stavien">
              Повече информация за СТАВИЕН можете да намерите тук.
            </Link>
          </h3>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default StavienStavi
